import * as React from "react"

// External packages
import { navigate } from "gatsby"
import { Box, Paragraph, Text } from "theme-ui"
import { Order } from "@medusajs/medusa"

// Hooks
import useLockedBody from "../../../hooks/v2/useLockedBody"

// Utilities
import { hasOrderGiftcard } from "../utils/order/hasOrderGiftcard"
import { hasOrderGiftcardOnly } from "../utils/order/hasOrderGiftcardOnly"

// Components
import { Link } from "../Link"
import { Button } from "../Button"
import { useStore } from "../../../context/NewStoreContext"

interface ConfirmationProps {
  order: Order
  isSplitOrder?: boolean
}

const Confirmation: React.FC<ConfirmationProps> = ({
  order,
  isSplitOrder = false,
}) => {
  const store = useStore()
  const [locked, setLocked] = useLockedBody(false)

  React.useEffect(() => {
    setLocked(false)
  })

  React.useEffect(() => {
    if (store.cart?.id) {
      if (isSplitOrder && store.cart?.id === order?.id) {
        store.clearCart()
      } else if (!isSplitOrder && store.cart?.id === order?.cart_id) {
        store.clearCart()
      }
    }
  }, [store.cart])

  return order ? (
    <Box>
      <Paragraph sx={{ fontSize: "xl", marginBlockEnd: 6 }}>
        Thank you for your order!
      </Paragraph>
      <Box sx={{ fontSize: "sm", marginBlockEnd: 11 }}>
        {hasOrderGiftcard(order) ? (
          <Paragraph sx={{ marginBlockEnd: 6 }}>
            The order confirmation and the gift card has been sent to:{" "}
            <strong>{order.email}</strong>
          </Paragraph>
        ) : isSplitOrder ? (
          <Paragraph sx={{ marginBlockEnd: 6 }}>
            The order confirmations have been sent to:{" "}
            <strong>{order.email}</strong>
          </Paragraph>
        ) : (
          <Paragraph sx={{ marginBlockEnd: 6 }}>
            The order confirmation has been sent to:{" "}
            <strong>{order.email}</strong>
          </Paragraph>
        )}
        {!isSplitOrder ? (
          <Paragraph sx={{ marginBlockEnd: 1 }}>
            Order number: <strong>{order.display_id}</strong>
          </Paragraph>
        ) : null}
        <Paragraph sx={{ marginBlockEnd: 1 }}>
          Order status: <Text sx={{ color: "green" }}>Confirmed</Text>
        </Paragraph>
        <Paragraph sx={{ marginBlockEnd: 1 }}>
          {hasOrderGiftcardOnly(order) ? (
            <>Billing address: </>
          ) : (
            <>Shipping address: </>
          )}
          {`${order?.shipping_address?.address_1}, ${order?.shipping_address?.postal_code} ${order?.shipping_address?.city}`}
        </Paragraph>
      </Box>
      <Button
        sx={{ width: 65, marginBlockEnd: 11 }}
        onClick={() => navigate("/")}
      >
        Back to shop
      </Button>
      <Paragraph sx={{ fontSize: "md", marginBlockEnd: 5 }}>
        Stay in touch
      </Paragraph>
      <Box sx={{ fontSize: "xs", marginBlockEnd: 10 }}>
        <Box sx={{ marginBlockEnd: 1 }}>
          <Link to="https://www.instagram.com/teklafabrics/" target="_blank">
            Instagram
          </Link>
        </Box>
        <Box sx={{ marginBlockEnd: 1 }}>
          <Link to="https://www.pinterest.dk/teklafabrics/" target="_blank">
            Pinterest
          </Link>
        </Box>
        <Box sx={{ marginBlockEnd: 1 }}>
          <Link to="https://www.facebook.com/teklafabrics" target="_blank">
            Facebook
          </Link>
        </Box>
        <Box sx={{ marginBlockEnd: 1 }}>
          <Link
            to="https://www.linkedin.com/company/teklafabrics/"
            target="_blank"
          >
            LinkedIn
          </Link>
        </Box>
        <Box>
          <Link
            to="https://open.spotify.com/user/bj3q21zapzgtcl03gy9o7x2of?si=ON0IdDyLTb6ZmXmyAOQl5w&nd=1"
            target="_blank"
          >
            Spotify
          </Link>
        </Box>
      </Box>
    </Box>
  ) : null
}

export default Confirmation
